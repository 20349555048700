// old styles

.mat-sort-header-content {
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.mat-drawer-inner-container {
  width: 90% !important;
  height: 100%;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.mat-form-field-infix {
    padding: 0.5em 0;
    border-top: 5px !important;
}

// colors

$white: #FFF;
$black: #000000;
$bgPrimary: #5D7488;
$bgPrimaryHover: #8d9dab;
$bgPrimaryDisabled: #9dabb7;
$bgSecondary: #D1D9D5;
$headingBlue: #133552;
$bgGreen: #5E9732;
$bgDarkBlue: #013555;

$bgLightGreen: #DAEBCD;
$bgLightGrey: #D1D9D5;
$bgLightestGreen: #eef7f3;
$bgOrange: #c7401f;
$bgBlueGrey: #415564;
$bgRuby: #aa1212;
$LightbgRuby: #aa5555;

.text-bg-nmftaprimary {
  color: $white !important;
  background-color: $bgPrimary;
  font-family: Arial, Helvetica, sans-serif;
}


.text-bg-nmftaprimary-danger {
  color: $white !important;
  background-color: $bgRuby;
  font-family: Arial, Helvetica, sans-serif;
}

.text-bg-nmftaprimary-danger:hover {
  color: $white !important;
  background-color: $LightbgRuby;
}

.text-bg-nmftaprimary:hover, .text-bg-nmftaprimary:focus {
  background-color: $bgPrimaryHover;
}

.text-bg-nmftaprimary:disabled {
  background-color: $bgPrimaryDisabled;
}

.text-bg-nmftasecondary {
  color: $black!important;
  background-color: $bgSecondary;
  font-family: Arial, Helvetica, sans-serif;
}

// body
html, body {
  color: $black;
  height: 100%;
  font-size: 16px;
}

.fill-screen {
  min-height: 72vh;
}

// text
h1 {
  color: $headingBlue !important;
  margin-top: 1rem;
}

h1, h2, h3, h4, h5, h6, label {
  font-family: 'Oswald', Arial, Helvetica, sans-serif;
}

app-manage-products {
  mat-sidenav-container {
    height: auto !important;
  }
  
}

// tables
.table-nmfta-header {
  background-color: $bgLightGreen !important;
  font-weight: 700;
  font-family: 'Oswald',Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
}

th {
  background-color: $bgLightGreen !important;
  font-family: 'Oswald',Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  padding: 0.2rem 0.5rem;
}

td {
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif;
  font-weight: 700;
  border-bottom-width: 0 !important;
  height: 3.5rem !important;
  min-height: 3.5rem !important;
  font-size: 0.875rem !important;
}

tr {
  height: 3.5rem !important;
  min-height: 3.5rem !important;
}

tr:hover {
  background-color: $bgPrimaryHover;
}

// buttons
.btn {
  font-family: Arial, Helvetica, sans-serif;
}

.close-btn {
  display: inline-flex;
  align-items: center;
}

// colors
.bg-orange {
  background-color: $bgOrange !important;
  color: white;
}

.bg-green {
  background-color: $bgGreen !important;
  color: white;
}

.heading-blue {
  color: $headingBlue;
}

.bg-dark-blue {
  background-color: $bgDarkBlue !important;
}

// logo
.scac-logo {
  width: 125px;
}

// mat-card
mat-card {
  background-color: $bgSecondary !important;
  font-family: 'Roboto';
}

// mat-icon
.lg-icon {
  transform: scale(2);
}

.mat-icon {
  margin: 0px !important;
  font-size: 1.525rem !important;
  height: 1.525rem !important;
  width: 1.525rem !important;
}

button[mat-icon-button] {
  align-items: center;
  display: flex;
}

button[mat-menu-item] {
  gap: 10px;
}

.icon-text-btn {
  display: inline-flex !important;
  align-items: center !important;
}

.icon-text-btn mat-icon {
  margin-right: 0.5rem;
}

// mat-checkbox
mat-checkbox {
  padding: 1rem;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element, .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $bgOrange !important;
}

// mat-select
.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 9998 !important;
}

.mat-mdc-select-disabled {
  background-color: #e9ecef;
  color: #212529 !important;
}

.mat-mdc-select-disabled.form-control:focus {
  background-color: #e9ecef;
  color: #212529;
  border-color: #ced4da !important;
  outline: 0;
  box-shadow: none;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 9999 !important;
}
// mat-spinner
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $bgGreen;
}

// mat-form-field
table .mat-form-field {
  display: inline-block;
  position: relative;
  text-align: left;
  width: 100%;
}

table .mat-form-field-underline {
  position: absolute;
  width: 95%;
  pointer-events: none;
  transform: scale3d(1, 1.0001, 1);
}

.mat-calendar {
  width: 296px !important;
  height: auto !important;
}

// mat-sidenav
mat-sidenav-container {
  background-color: white !important;
  min-height: 800px !important;
}

// widths
.s-w {
  width: 8rem;
  min-width: 8rem;
}

.m-w {
  width: 16rem;
  min-width: 16rem;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
  background-color: transparent !important;
}

label {
  position: relative;
}

.error-input-message {
  color: red;
  top: 5px;
  position: relative;
}

.required-field {
  color: red!important;
  font-size: x-large;

  &.apply {
    position: absolute;
    top: -10px;
    right: -10px;
  }
}

mat-dialog-container {
  .mdc-dialog__surface {
    padding: 20px;
  }
}
.disabled-input {
  background-color: #eaecef;
  pointer-events: none;
 }



